<template>
    <div>
        <loadingScreen/>
        <nav class="navbar navbar-light bg-primary">
            <a class="">
                <router-link to="/" @click="isCompany = false">
                    <img style="max-width: 100px; max-height: 55px"
                         alt="R2 Soft"
                         :src="allotmentImg ? allotmentImg : '@/assets/img/logo-pb.png'"
                    >
                </router-link>
            </a>
        </nav>
        <div class="main-container">
            <div class="row gutters" :class="this.widthMd(600) ? '': 'pt-3'">
                <div class="col-1" v-if="!this.widthMd(600)"></div>
                <div :class="!this.widthMd(600) ? 'col-10' : 'col-12'">
                    <div class="card">
                        <div class="card-body" :class="!this.widthMd(600) ? '': 'pr-0 pl-0'">
                            <div class="row gutters">
                                <div class="col-12 d-flex justify-content-center flex-column align-items-center text-justify">
                                    <p class="list-primary  pl-1 pt-0 pb-0" style="font-size: 20px"><i
                                            class="icon-user-plus"></i>
                                        Cadastro de Corretores</p>
                                    <p class="list-primary pt-0" style="font-size: 17px"> Loteamento: {{
                                            allotmentName
                                        }}</p>
                                </div>
                                <div class="dropdown-divider col-12"></div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="name" class="required">{{ t('GENERAL.NAME') }}: </label>
                                        <input type="text" id="name" name="name" v-model="user.name"
                                               @change="validadeFieldInRealTime('name')"
                                               class="form-control" placeholder="Nome Completo">
                                        <div v-if="invalid.name" class="validation">
                                            {{ t(invalid.name) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="required" for="taxpayer">{{ t('COMPANIES.TAXPAYER') }}:</label>
                                        <input type="text" id="taxpayer" name="taxpayer" v-model="user.taxpayer"
                                               ondrop="return false" class="form-control"
                                               @change="validadeFieldInRealTime('taxpayer')"
                                               placeholder="000.000.000-00"
                                               v-mask="['###.###.###-##', '##.###.###/####-##']">
                                        <div v-if="invalid.taxpayer" class="validation">
                                            {{ t(invalid.taxpayer) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="email" class="required">{{ t('GENERAL.EMAIL') }}: </label>
                                        <input type="text" id="email" name="email" v-model="user.email"
                                               class="form-control" onpaste="return false"
                                               @change="validadeFieldInRealTime('email')"
                                               placeholder="Endereço de e-mail">
                                        <div v-if="invalid.email" class="validation">
                                            {{ t(invalid.email) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="email" class="required">Confirmar {{ t('GENERAL.EMAIL') }}: </label>
                                        <input type="text" id="email" name="email" v-model="user.emailConfirm"
                                               class="form-control" onpaste="return false"
                                               @change="validadeFieldInRealTime('emailConfirm')"
                                               placeholder="Endereço de e-mail">
                                        <div v-if="invalid.emailConfirm" class="validation">
                                            {{ t(invalid.emailConfirm) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="required">Imobiliaria: </label>
                                        <Select2 :settings="ajaxPerson" @select="selectImob"
                                                 v-model="user.real_estate_agency_id"
                                                 placeholder="Selecione">
                                        </Select2>
                                        <div v-if="invalid.real_estate_agency_id" class="validation">
                                            {{ t(invalid.real_estate_agency_id) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="birthdate" class="required">{{
                                                (user?.taxpayer?.length > 14) ? t('COMPANIES.BIRTHDATE_COMAPANY') : t('COMPANIES.BIRTHDATE')
                                            }}:</label>
                                        <input type="date" id="birthdate" name="birthdate" v-model="user.birthday"
                                               @change="validadeFieldInRealTime('birthdate')"
                                               min="1900-01-01"
                                               class="form-control">
                                        <div v-if="invalid.birthday" class="validation">
                                            {{ t(invalid.birthday) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                    <div class="form-group">
                                        <label for="email" class="required">{{ t('GENERAL.CRECI') }}: </label>
                                        <input type="text" id="creci" name="creci" v-model="user.creci"
                                               @change="validadeFieldInRealTime('creci')"
                                               class="form-control"
                                        >
                                        <div v-if="invalid.creci" class="validation">
                                            {{ t(invalid.creci) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="padding: 0">
                                    <phoneComponentInline :required="true"
                                                          :phonesIndex="user.phones"
                                                          @alterDescription="alterDescription"
                                                          @alterType="alterType"
                                                          @alterNumber="alterNumber"
                                                          :isInvalid="invalid.phones"
                                                          :clear-data-phone="clearDataPhone"
                                                          @cleaned="cleanedPhone"
                                                          @update="updatePhone($event)"
                                                          @create="createPhone($event)"
                                    ></phoneComponentInline>
                                    <div v-if="invalid.phones" class="validation">
                                        {{ t(invalid.phones) }}
                                    </div>
                                </div>
                            </div>
                            <div class="dropdown-divider mt-2 mb-2"></div>
                            <div class="d-flex flex-row justify-content-end">
                                <button class="btn btn-primary" @click="valideFields">{{ t('ACTIONS.SAVE') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-1" v-if="!this.widthMd(600)"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import LoadingScreen from '@/components/layouts/loadScreenComponent';
import widthMd from '../../mixins/widthMd';
import Select2 from 'vue3-select2-component';
import phoneComponentInline from '../../components/layouts/phoneComponentInline';
import User from '../../services/user';
import Validate from '@/components/validate.vue';


export default {
    name: "createPublicComponent",
    components: {
        LoadingScreen,
        Select2,
        phoneComponentInline,
    },
    data() {
        return {
            user: {active: false, is_password_generic: true, password: null},
            invalid: {},
            phone: {},
            clearDataPhone: false,
            allotmentImg: null,
            allotmentName: null,
            ajaxPerson: {
                width: '100%',
                language: {
                    noResults: () => "Nenhum resultado encontrado!",
                    searching: () => "Buscando...",
                },
                minimumInputLength: 0,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/publica/imobiliarias/list`,
                    dataType: "json",
                    headers: {'allotment-id': this.$route.params.allotmentId,},
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 'selecione', text: "Nenhum"});
                            return {
                                results: data.map((i) => ({id: i.id, text: i.text}))
                            };
                        } else {
                            return {results: {id: 'selecione', text: "Nenhum"}};
                        }
                    },
                    cache: true
                },
            },
        }
    },
    mounted() {
        this.ajaxPerson.ajax.headers['allotment-id'] = this.$route.params.allotmentId;
        this.validateToken();
    },
    mixins: [widthMd, Validate],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    methods: {
        validateToken() {
            this.$store.commit('changeLoading', true);
            User.validateTokenPublicUser(this.$route.params.token, this.$route.params.allotmentId).then((resp) => {
                this.allotmentName = resp?.data?.name;
                this.allotmentImg = resp?.data?.image;
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.errorMsg(err);
                this.$router.push({name: 'Home'});
                this.$store.commit('changeLoading', false);
            })
        },
        selectImob({id, text}) {
            if (id === 'selecione') {
                this.user.real_estate_agency_id = null;
                this.user.real_estate_agency_name = null;
                this.invalid.real_estate_agency_id = 'Campo obrigatório';
            } else {
                this.user.real_estate_agency_id = id;
                this.user.real_estate_agency_name = text;
                this.invalid.real_estate_agency_id = null;
            }
        },
        alterDescription(val) {
            this.phone.description = val;
        },
        validadeFieldInRealTime(action) {
            switch (action) {
                case 'name':
                    if (!this.user.name) {
                        this.invalid.name = 'Campo obrigatório';
                    } else if (this.user.name?.length < 8) {
                        this.invalid.name = 'Mínimo 8 caracteres';
                    } else this.invalid.name = null;
                    break;
                case 'taxpayer':
                    if (!this.user.taxpayer) {
                        this.invalid.taxpayer = 'Campo obrigatório';
                    } else if (this.user.taxpayer?.length !== 14 && this.user.taxpayer?.length !== 18) {
                        this.invalid.taxpayer = 'Campo inválido';
                    } else if (this.user.taxpayer?.length === 14 && !this.validateCpf(this.user.taxpayer)) {
                        this.invalid.taxpayer = 'CPF inválido';
                    } else if (this.user.taxpayer?.length === 18 && !this.validateCNPJ(this.user.taxpayer)) {
                        this.invalid.taxpayer = 'CNPJ inválido';
                    } else this.invalid.taxpayer = null;
                    break;
                case 'email':
                    if (!this.user.email) {
                        this.invalid.email = 'Campo obrigatório';
                    } else if (this.user.email?.length < 5) {
                        this.invalid.email = 'Campo inválido';
                    } else this.invalid.email = null;
                    break;
                case 'emailConfirm':
                    if (!this.user.emailConfirm) {
                        this.invalid.emailConfirm = 'Campo obrigatório';
                    } else if (this.user.emailConfirm?.length < 5) {
                        this.invalid.emailConfirm = 'Campo inválido';
                    } else if (this.user.email !== this.user.emailConfirm) {
                        this.invalid.email = 'E-mails devem ser iguais';
                        this.invalid.emailConfirm = 'E-mails devem ser iguais';
                    } else {
                        this.invalid.email = null;
                        this.invalid.emailConfirm = null;
                    }
                    break;
                case 'birthdate':
                    if (!this.user.birthday) {
                        this.invalid.birthday = 'Campo obrigatório';
                    } else this.invalid.birthday = null;
                    this.validateDateMin();
                    break;
                case 'creci':
                    if (!this.user.creci) {
                        this.invalid.creci = 'Campo obrigatório';
                    } else this.invalid.creci = null;
                    break;
            }
        },
        validateDateMin() {
            if (!this.user.birthday) return;
            const dataSelecionada = new Date(this.user.birthday);
            const year = dataSelecionada.getFullYear();
            if (year && year < 1900) {
                dataSelecionada.setFullYear(1900);
                const ano = dataSelecionada.getFullYear();
                const mes = String(dataSelecionada.getMonth() + 1).padStart(2, '0');
                const dia = String(dataSelecionada.getDate()).padStart(2, '0');
                this.user.birthday = `${ano}-${mes}-${dia}`;
            }
        },
        valideFields() {
            let valid = true
            if (!this.user.name) {
                this.invalid.name = 'Campo obrigatório';
                valid = false;
            } else if (this.user.name?.length < 8) {
                this.invalid.name = 'Mínimo 8 caracteres';
                valid = false;
            } else {
                this.invalid.name = null;
            }
            if (!this.user.taxpayer) {
                this.invalid.taxpayer = 'Campo obrigatório';
                valid = false;
            } else if (this.user.taxpayer?.length !== 14 && this.user.taxpayer?.length !== 18) {
                this.invalid.taxpayer = 'Campo inválido';
                valid = false;
            } else if (this.user.taxpayer?.length === 14 && !this.validateCpf(this.user.taxpayer)) {
                this.invalid.taxpayer = 'CPF inválido';
                valid = false;
            } else if (this.user.taxpayer?.length === 18 && !this.validateCNPJ(this.user.taxpayer)) {
                this.invalid.taxpayer = 'CNPJ inválido';
                valid = false;
            } else {
                this.invalid.taxpayer = null;
            }
            if (!this.user.email) {
                this.invalid.email = 'Campo obrigatório';
                valid = false;
            } else if (this.user.email?.length < 5) {
                this.invalid.email = 'Campo inválido';
                valid = false;
            } else {
                this.invalid.email = null;
            }
            if (!this.user.emailConfirm) {
                this.invalid.emailConfirm = 'Campo obrigatório';
                valid = false;
            } else if (this.user.emailConfirm?.length < 5) {
                this.invalid.emailConfirm = 'Campo inválido';
                valid = false;
            } else if (this.user.email !== this.user.emailConfirm) {
                this.invalid.email = 'E-mails devem ser iguais';
                this.invalid.emailConfirm = 'E-mails devem ser iguais';
            } else {
                this.invalid.email = null;
                this.invalid.emailConfirm = null;
            }
            if (!this.user.real_estate_agency_id) {
                this.invalid.real_estate_agency_id = 'Campo obrigatório';
                valid = false;
            } else {
                this.invalid.real_estate_agency_id = null;
            }
            if (!this.user.creci) {
                this.invalid.creci = 'Campo obrigatório';
                valid = false;
            } else {
                this.invalid.creci = null;
            }
            if (!this.user.birthday) {
                this.invalid.birthday = 'Campo obrigatório';
                valid = false;
            } else {
                this.invalid.birthday = null;
            }
            if (!this.phone.type && !this.phone.number && !this.user?.phones?.length) {
                this.invalid.phones = 'Necessário informar um Telefone';
                valid = false;
            } else {
                this.invalid.phones = null;
            }
            if (valid) {
                this.create();
            }
        },
        create() {
            this.$store.commit('changeLoading', true);
            let data = {user: JSON.parse(JSON.stringify(this.user))};
            if (this.phone.type && this.phone.number) {
                if (!data.user?.phones?.length) {
                    data.user.phones = [];
                }
                data.user.phones.push({
                    description: this.phone?.description ? this.phone.description : null,
                    type: this.phone.type,
                    number: this.phone.number,
                    code_country: this.phone.code_country ? this.phone.code_country : 55,
                    country_acronym: this.phone.country_acronym ? this.phone.country_acronym : 'BR',
                })
            }
            User.createPublic(data, this.$route.params.allotmentId, this.$route.params.token).then(() => {
                this.toast.success('Usuário cadastrado com sucesso!');
                this.resetDatas();
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(err);
            });
        },
        alterType(val) {
            this.phone.type = val;
        },
        alterNumber(val) {
            this.phone.number = val.number;
            this.phone.code_country = val.code_country;
            this.phone.country_acronym = val.country_acronym;
        },
        createPhone(phones) {
            this.phone.description = null;
            this.phone.type = null;
            this.phone.number = null;
            this.user.phones = phones;
        },
        updatePhone(phones) {
            this.user.phones = phones;
        },
        cleanedPhone() {
            this.clearDataPhone = false;
        },
        resetDatas() {
            this.user = {active: false, is_password_generic: true, password: null};
            this.invalid = {};
            this.phone = {};
            this.clearDataPhone = true;
            setTimeout(() => this.clearDataPhone = false, 50)
        },
    }
}
</script>

<style lang="less">
.size {
    width: 200px !important;
}

.size-modal {
    width: 100% !important;
}

.btn-background {
    width: 100%;
    font-size: large !important;
    font-weight: 600 !important;
    background-color: #adadad !important;
}

.modal-dialog {
    overflow-y: initial !important
}

.modal-row2 {
    max-height: 40vh;
    overflow-y: auto;
}

.modal-row {
    max-height: 40vh;
    overflow-y: auto;
}

.modal-row2::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}

.modal-row2::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}

.modal-mask {
    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-header-color {
    color: white;
    background-color: #0e5e4e !important;
}

.right-content {
    justify-content: flex-end;
}

.swal2-container {
    z-index: 100000000000;
}

.user-avatar {
    max-width: 80px !important;
    max-height: 80px !important;
    border-radius: 50% !important;
    margin-bottom: 1.2rem !important;
}

.avatar-size {
    width: 80px !important;
    height: 80px !important;
}

.cropper-size {
    max-width: 350px !important;
    max-height: 275px !important;
}

.avatar .status {
    top: 62px;
    right: 2px;
    width: 25px;
    height: 25px;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    border: 4px solid #f0f0f0;
}

.camera {
    right: 1;
    bottom: 0;
    outline: 0;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    background-color: #f0f0f0;
}

.align-items {
    align-items: center;
}

.preview {
    border: dashed 1px rgba(255, 255, 255, 0.25);
}

.cropper {
    height: 100%;
    background: #DDD;
}

.hearder-custom {
    color: #333333 !important;
    background: #ffffff !important;
}

.close-custom {
    color: #000 !important;
}

.border-bottom {
    border-bottom: 1px solid #e6ebf1;
}

.border-top {
    background-color: #f2f4f9;
    border-top: 1px solid #e6ebf1;
}

.lg {
    max-width: 800px !important;
}
</style>

<style>

body.authentication {
    display: block;
    align-items: flex-start;
    justify-content: left;
    background: #eff1f5;
}

.modal-row {
    max-height: 100%;
    overflow-y: auto;
}

.modal-row::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
    background: transparent !important;
}

.modal-row::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 10px;
}

.secondary {
    border-radius: 3px;
    color: #ffffff !important;
    background-color: #383737;
}

.info-lot {
    border-radius: 7px;
    padding-bottom: 1px;
    padding-top: 6px;
}

.space-between {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.color-grey {
    color: #727272;
}

.font {
    font-size: 0.8rem;
}

.fab {
    position: fixed;
    bottom: 5px;
    right: 10px;
}

.fab button {
    cursor: pointer;
    width: 100%;
    height: 45px;
    border-radius: 20px;
    background-color: #383737;
    border: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .4);
    font-size: 0.9rem;
    color: black;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab button.main {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background-color: #0e5e4e !important;
    right: 0;
    bottom: 0;
    z-index: 20;
}

.fab ul {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    padding-right: 5px;
    margin: 0;
    list-style: none;
    z-index: 10;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab ul li {
    display: flex;
    justify-content: flex-start;
    position: relative;
    margin-bottom: -10%;
    opacity: 0;

    -webkit-transition: .3s ease-out;
    -moz-transition: .3s ease-out;
    transition: .3s ease-out;
}

.fab ul li label {
    margin-right: 10px;
    white-space: nowrap;
    display: block;
    margin-top: 10px;
    padding: 5px 8px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
    border-radius: 3px;
    height: 18px;
    font-size: 16px;
    pointer-events: none;
    opacity: 0;

    -webkit-transition: .2s ease-out;
    -moz-transition: .2s ease-out;
    transition: .2s ease-out;
}

.fab button.main:active,
.fab button.main:focus {
    outline: none;
    background-color: #000000;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .5);
}

.fab button.main:active + ul,
.fab button.main:focus + ul {
    bottom: 70px;
}

.fab button.main:active + ul li,
.fab button.main:focus + ul li {
    margin-bottom: 8px;
    opacity: 1;
}

.fab button.main:active + ul li:hover label,
.fab button.main:focus + ul li:hover label {
    opacity: 1;
}
</style>

<style scoped>
.btn-holver {
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
    cursor: pointer;
}

.bg-btn {
    background-color: #f2f4f9 !important;
    color: black !important;
}

.bg-btn:hover {
    background-color: #0e5e4e !important;
    color: white !important;
}

.custom-checkbox {
    padding-left: 32px !important;
}

.pointer {
    cursor: pointer;
}

@media screen and (max-width: 424px) {
    .mt-m-2 {
        margin-top: 1.1rem !important;
    }
}
</style>